import React from 'react'


export const History = () => {
    return (
      <div className='history'>
        <h1>History</h1>
      </div>
    );
  };
  

  
export const HistoryOne = () => {
    return (
      <div className='history'>
        <h1>history1</h1>
      </div>
    );
  };
