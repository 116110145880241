import React from 'react';
import './css/ProgressBar.css';
import pending from './icon/pending.png';
import inprogress from './icon/inprogress.png';
import completed from './icon/completed.png';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const ProgressBar = ({ bgcolor, progress, height, status, label }) => {
 const parentDivStyle = {
    height: height,
    width: '100%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
    margin: '120px 30px',
    top: -30,
    position: 'relative', // Added position relative to the parent div
 }; 

 const childDivStyle = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right',
    fontSize: '23px',
    position: 'absolute', // Added position absolute to the child div
    top: 0, // Aligned the child div to the top of the parent
    left: 0, // Aligned the child div to the left of the parent
    
 };
 
 // progress bar status ( Pending, In-progress, Completed )
 const titleStyle = {
   position: 'absolute',
   top: '30px',
   left: '10px',
   fontSize: '23px',
   fontWeight: 'bold',
   display: 'flex',
   margin: '0 -5px',
 };

// Progressbar number out of 100
 const progressTextStyle = {
  padding: 24,
  color: 'black', 
  fontWeight: 900,
  position: 'absolute',
  textAlign: 'right', 
  top: '100%', 
  right: 0, 
  top : 10,
  left: 290,
};

 const getImageForStatus = (status) => {
  switch (status) {
    case 'pending':
      return pending;
    case 'inprogress':
      return inprogress;
    case 'completed':
      return completed;
    default:
      return null;
  }
};

const statusText = {
  pending: 'Pending',
  inprogress: 'In Progress',
  completed: 'Completed',
};

// Add margin to the right of the icon
const iconStyle = {
  marginRight: '8px', 
  height:'19px',
  width:'19px',
  margin: '10px'
};


  // Use label if provided and status is "completed", otherwise show the progress percentage
const displayText = status === "completed" && label ? label : `${progress}%`;


 return (
    <div style={parentDivStyle}>
      <div style={childDivStyle}>
        <span style={progressTextStyle}>{displayText}</span> 
      </div>
      <div style={titleStyle}>
      <img className='status-icon' src={getImageForStatus(status)} style={iconStyle} />
      <span> {statusText[status]} </span>
      {/*<span>{getStatusTitle(status)}</span>*/}
      </div>

    </div>
 );
};

export default ProgressBar;