import React, { useState, useEffect } from 'react';
import { PieChart,  Pie, Cell } from 'recharts';

import './css/DonutChart.css';
import { colors } from '@mui/material';

const DonutChart = () => {
  const [chartData, setChartData] = useState({
    idleAssets: 0,
    activeDefects: 0,
    activeDrivers: 0 // Fixed the typo here
  });

  useEffect(() => {
    fetch('http://localhost:5050/dashboard/dashboard-details')
      .then(response => response.json())
      .then(data => {
        setChartData(data); // Assuming the API response matches the shape of chartData
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const { idleAssets, activeDefects, activeDrivers } = chartData;

  // Calculate the percentage for totalDrivers  
  const percentageFilled1 = (idleAssets / 360) * 100;
  const percentageFilled2 = (activeDefects / 360) * 100;
  const percentageFilled3 = (activeDrivers / 360) * 100; // Assuming 360 degrees for full circle

  
  // Data for Pie component
  const data1 = [
    { name: 'Filled', value: idleAssets },
    { name: 'Remaining', value: 360 - idleAssets }
  ];
  const data2 = [
    { name: 'Filled', value: activeDefects },
    { name: 'Remaining', value: 360 - activeDefects }
  ];
  const data3 = [
    { name: 'Filled', value: activeDrivers },
    { name: 'Remaining', value: 360 - activeDrivers }
  ];


  
  //const data1 = [{ name: 'Idle Assets', value: idleAssets }];
  //const data2 = [{ name: 'Active Defects', value: activeDefects }];
  //const data3 = [{ name: 'Total Drivers', value: totalDrivers }]; 

  return (
    <>
      <div className='lgraph'>
        <div className='graph1'>
          <div style={{ textAlign: "center", margin: "auto 10%", fontSize: "10px" }}>
          <PieChart width={220} height={220}>
            {/* Filled portion */}
          <Pie
            data={data1}
            //dataKey='value'
            startAngle={-90}
            endAngle={360 - percentageFilled1 }
            innerRadius={50}
            outerRadius={80}
          >
            <Cell fill='#EFEDED' />
            
          </Pie>
            {/* Remaining portion */}
            <Pie
              data={data1}
              //dataKey='value'
              startAngle={360 - percentageFilled1 }
              endAngle={-90}
              innerRadius={50}
              outerRadius={80}
            >
              <Cell fill='#7C7CE4' />
            </Pie>
            </PieChart>
          </div>
        </div>

        <div className='graph2'>
          <div style={{ textAlign: "center", margin: "auto 10%", fontSize: "10px" }}>
            <PieChart width={220} height={220}>
            {/* Filled portion */}
          <Pie
            data={data2}
            //dataKey='value'
            startAngle={-90}
            endAngle={360 - percentageFilled2 }
            innerRadius={50}
            outerRadius={80}
          >
            <Cell fill='#EFEDED' />
          </Pie>
            {/* Remaining portion */}
            <Pie
              data={data2}
              //dataKey='value'
              startAngle={360 - percentageFilled2 }
              endAngle={-90}
              innerRadius={50}
              outerRadius={80}
            >
              <Cell fill='#38D39F' />
            </Pie>
            </PieChart>
          </div>
        </div>
      </div>

      <div className='graph3-container'>
        <div className='graph3'>
          <div style={{ textAlign: "center", margin: "auto 10%", fontSize: "10px" }}>
          <PieChart width={350} height={350}>
            {/* Filled portion */}
            <Pie
              data={data3}
              //dataKey='value'
              startAngle={-90}
              endAngle={360 - percentageFilled3}
              innerRadius={100}
              outerRadius={140}
            >
              <Cell fill='#EFEDED' />
            </Pie>
            {/* Remaining portion */}
            <Pie
              data={data3}
              //dataKey='value'
              startAngle={360 - percentageFilled3}
              endAngle={-90}
              innerRadius={100}
              outerRadius={140}
            >
              <Cell fill='#064CD3' />
            </Pie>
          </PieChart>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonutChart;
