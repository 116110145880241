import React, { useState } from 'react';
import icon from '../components/home.png';
import notification from '../components/Notifications.png';
import profileicon from '../components/Ellipse.png';
import profiledown from '../components/ProfileDown.png';
import '../pages/css/addDriver.css';

const ToggleButton = ({ isActive, handleToggle }) => (
  <div>
    <label>Active Driver:</label>
    <label className="switch">
      <input type="checkbox" checked={isActive} onChange={handleToggle} />
      <span className="slider round"></span>
    </label>
  </div>
);

const AddDriver = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Access the selected file
    console.log('Selected file:', file);
    // You can handle the file here (e.g., upload it to a server, store it in state, etc.)
  };

  return (
    <div>
      {/* Navigation Bar */}
      <div className="nav">
        <nav id="nav">
          <img src={icon} alt="Home Icon" className="nav-icon" />
          <img src={notification} className="notificationicon" alt="Notification icon" />
          <img src={profileicon} className="profileicon" alt="Profile icon" />
          <img src={profiledown} className="profiledown" alt="Profile dropdown" />
          <h4 className="username">James Louis</h4>
          <h4 className="navtitle" style={{marginTop:'-25px'}} >Driver Management</h4>
        </nav>
      </div>

      {/* Add Driver Form */}
      <div className='container'>
        <div className='left-container'>
          <label htmlFor="name">Enter Forename</label><br />
          <input type="text" placeholder="Enter Forename" />
          <label htmlFor="number">D.O.B</label><br />
          <input type="number" placeholder="Enter D.O.B" />
          <label htmlFor="number">WhatsApp Number</label><br />
          <input type="number" placeholder="Enter WhatsApp Number" />
          <label htmlFor="number">DQC Card Number</label><br />
          <input type="number" placeholder="Enter DQC Card Number" />
          <label htmlFor="password">Enter Temporary Password</label><br />
          <input type="password" placeholder="Enter Password" />
          <label htmlFor="number">Driver Licence Number</label><br />
          <input type="number" placeholder="Enter Driver Licence Number" />
          <ToggleButton isActive={isActive} handleToggle={handleToggle} />
        </div>

        <div className='right-container'>
          <label htmlFor="text">Enter Surname</label><br />
          <input type="text" placeholder="Enter Surname" />
          <label htmlFor="email">Enter Email Address</label><br />
          <input type="email" placeholder="Enter Email Address" />
          <label htmlFor="text">Nationality</label><br />
          <input type="text" placeholder="Enter Nationality" />
          <label htmlFor="name">Techograph No</label><br />
          <input type="text" placeholder="Enter Techograph No" />
          <label htmlFor="manu">Gender</label><br />
          <select id="manu" name="gender">
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <div className='file'>
            <input type="file" onChange={handleFileUpload} />
          </div>
        </div>
      </div>

      {/* Additional Information */}
      <div className='additional-info'>
        <h4 className="additional-info-title">Other Information</h4>
        <div className='additional-container'>
          <div className='add-left-container'>
            <label htmlFor="text"> Previous Company </label><br />
            <input type="text" placeholder="Enter the Previous Company" />
            <label htmlFor="text"> Years of Experience </label><br />
            <input type="text" placeholder="Enter the Years of Experience" />
            <label htmlFor="text">Residential Address</label><br />
            <input type="text" placeholder="Enter the Residential Address" />
            <label htmlFor="text">Mobile Tel</label><br />
            <input type="text" placeholder="Enter the Mobile Tel" />
            <label htmlFor="text">Residential To You</label><br />
            <input type="text" placeholder="Enter your Relationship To You" />
            <label htmlFor="text">Postcode</label><br />
            <input type="text" placeholder="Enter the Postcode" />
            <label htmlFor="text">Other Document</label><br />
            <input type="text" placeholder="Enter the name of Document" />
            <div className='Other-Document'> <input type="file" onChange={handleFileUpload} /></div>
          </div>


          <div className='add-right-container'>
            <label htmlFor="text">Home Tel</label><br />
            <input type="text" placeholder="Enter the Home Tel" />
            <label htmlFor="text">Next of kin</label><br />
            <input type="text" placeholder="Enter the Next of kin" />
            <label htmlFor="text">County</label><br />
            <input type="text" placeholder="Enter the County" />
            <label htmlFor="text">Id Proof</label><br/>
            <div className='id-proof'> <input type="file" onChange={handleFileUpload} /></div>
            <label htmlFor="text">Insurance Document</label><br />
            <div className='Insurance-Document'> <input type="file" onChange={handleFileUpload} /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDriver;
