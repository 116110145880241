import React, { useState, useEffect } from 'react';
//import React from 'react'
import './css/Dashboard.css';
import total_assets from './icon/Total_assets.png';
import total_driver from './icon/Total_drivers.png';
import total_work_order from './icon/Total_work_orders.png';
import DonutChart from './DonutChart';
import ProgressBar from './ProgressBar';
import icon from '../components/home.png';
import notification from '../components/Notifications.png';
import profileicon from '../components/Ellipse.png'
import profiledown from '../components/ProfileDown.png'
import notificationstatus from '../pages/icon/notificationstatus.png'



const Dashboard = () => {

  const [dashboardData, setDashboardData] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:5050/dashboard/dashboard-details');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetch('http://localhost:5050/notification/getNotifications')
      .then(response => response.json())
      .then(data => {
       setNotificationCount(data.length);
      })
      .catch(error => {
        console.error('Error fetching notifications:', error);
      });
  }, []);
/*
  useEffect(() => {
    const handleWheel = (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-' || e.key === '0')) {
        e.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); */
  

  return (
    <>
    <div className='dashboard'>

    {/*
      <nav id='nav'>
      <img src={icon} alt="Home Icon" style={{ width: '30px', height: '30px', alignItems: 'center', marginRight: '6px', top: '0px' ,marginBottom: '10px', marginLeft: '16px'}}/> 
      <img src={notification} className= 'notificationicon'alt="Notification icon" style={{marginLeft:'710', height:'30px', width:'30px', marginTop:'10px', marginBottom: '5px', alignItems:'start'}} />
      <img src={profileicon} className='profileicon' />
      <img src={profiledown} className='profiledown'/>            
      <h4 style={{  height: 'auto', width:'auto', alignItems: 'center', marginLeft: '850px', marginTop: '-40px', marginBottom: '16px'}}>James Louis</h4>
      <h4 className="navtitle" style={{marginTop:'-50px', marginLeft:'50px', color:'#037E69'}}>Dashboard</h4>


      <div className='notification'> {/* Display notification count if there are notifications 
      <img src={notificationstatus} alt='notificationstatus' style={{marginTop: '-60px' , marginLeft: '540px'}}></img>
      {notificationCount > 0 && (
        <div style={{marginTop: '-58px' , marginLeft: '565px', display:'flex'}}>
          <span style={{marginTop:'-5px',fontSize:'23px', marginRight:'6px'}}>{notificationCount}</span> <h5> New Notifications </h5>
        </div>
      )}</div>
      </nav> */}

  <nav id='nav' className='nav'>
  <div className="home-icon">
    <img src={icon} alt="Home Icon"  />
  </div>
  <h4 className="nav-title">Dashboard</h4>  
  
    
    <div className="profile-area">
    <div className="notification-area">
  <img src={notificationstatus} alt='Notification Status' />
    {notificationCount > 0 && (
      <span>{notificationCount} New Notifications</span>
    )}

    <img src={notification} alt="Notification icon" style={{height:'30px', width:'30px', marginRight:'10px'}} />
    <img src={profileicon} alt="Profile Icon" style={{marginLeft:'0px', height:'40px', width:'40px'}}/>
    <h4 style={{fontSize:'30px', marginTop:'0px', color:'#2B2B2B', marginLeft:'10px', marginRight:'0px', fontFamily: 'Inter, sans-serif' }}>James Louis</h4>
    <img src={profiledown} style={{height:'30px', width:'35px'}} alt="Profile Dropdown" />
  </div>
  </div>

</nav>
      <div className='blocks'>
       <div className='block1'>
       <img src={total_assets} />
        <h1>{dashboardData ? dashboardData.totalAssets : '0'}</h1>
        <h4>Total Assets</h4>
      </div>
      <div className='block2'>
      <h1>{dashboardData ? dashboardData.idleAssets : '0'}</h1>
      <h4>Idle Assets</h4>
      </div>
      <div className='block3'>
      <h1>{dashboardData ? dashboardData.activeDefects : '0'}</h1>
      <h4>Assets with Active Defects</h4>
      </div>
      </div>

     <div className='rblock'>
      <div className='block4'>
      <img src={total_driver}/>
        <h1>{dashboardData ? dashboardData.totalDrivers : '0'}</h1>
        <h4>Total Drivers</h4>
        </div>
      <div className='block5'>
        <h1>{dashboardData ? dashboardData.activeDrivers : '0'}</h1>
        <h4>Active Drivers</h4>
      </div>
      </div>

      <div className='endrblock'>
      <div className='block6'>
      <img src={total_work_order} />
        <h1>{dashboardData ? dashboardData.totalWorkOrders : '0'}</h1>
        <h4>Total Work Orders</h4>
        </div>
      <div className='block7'>
        <h4>Work Order Summary</h4>
        <div className='ProgressBar' style={{fontFamily:'inter'}}> 
          <ProgressBar bgcolor='#CAEE5A' progress={100} height={18} status="pending" />
          <ProgressBar bgcolor='#4ECDC4' progress={100} height={18} status="inprogress"  />
          <ProgressBar bgcolor='#7A06B0' progress={dashboardData ? dashboardData.completedWorkOrders : 0} height={18} status="completed"  />
        </div>
      </div>
      </div>
      <div className='Donutchart'> <DonutChart /> </div>
    </div>

  </>
  );
};

export default Dashboard