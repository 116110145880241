import React from 'react';
import notification from '../components/Notifications.png';
import profileicon from '../components/Ellipse.png';
import profiledown from '../components/ProfileDown.png';
import notificationicon from '../components/Notifications.png';
import '../pages/css/Notifications.css'
import { useEffect, useState } from "react";
import axios from 'axios';
import { Container, Table } from 'react-bootstrap'; 
import { Cotainer, Card, ListGroup } from 'react-bootstrap';



const Notifications = () => {
  const [drivers, setDrivers] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5050/notification/getNotifications');
        setDrivers(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = drivers.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalRecords = drivers.length;



  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > Math.ceil(totalRecords / recordsPerPage)) {
      return; // Don't allow going to invalid pages
    }
    setCurrentPage(pageNumber);
  };

  


  return (
   
    <div className='notifications'>

      {/* Nav */}
      <nav id='nav' className='nav'>
      <div className="notification-icon" style={{marginLeft:'30px', marginRight:'-30px'}}> <img style={{height:'40px', width:'40px'}} src={notification}  />
      <h4 className="notification-title">Notifications</h4> </div>
      
      <div className="profiles-area">
      <div className="notification-area" ><img src={notification} style={{height:'30px', width:'30px', }} alt="Notification icon" />
      <img src={profileicon} alt="Profile Icon" style={{marginLeft:'0px', height:'40px', width:'40px', marginLeft:'10px'}}/>
      <h4 style={{fontSize:'30px', marginTop:'10px', color:'#2B2B2B', marginLeft:'10px', marginRight:'0px'}}>James Louis</h4>
      <img src={profiledown} style={{height:'30px', width:'35px'}} alt="Profile Dropdown" /></div>
      
    </div>
      </nav>

      {/* Table  */}
  <div className="table-container-notification">
  <Container>
    <Table striped bordered hover>
      {/* Table headers */}
      <thead>
        <tr>
          <th>{'  Asset Number'}</th>
          <th>{'  Driver Name'}</th>
          <th>{'  Notification Type'}</th>
          <th>{'  Action'}</th>
          <th>{'  User'}</th>
          <th>{'  Created At'}</th>
        </tr>
      </thead>
      {/* Table body */}
      
      <tbody>
        {currentRecords.map((item, index) => (
        <tr key={item._id} style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
            <td>{item.asset_id}</td>
            <td >{item.driver_id}</td>
            <td >{item.notification_type}</td>
            <td >{item.read_status}</td>
            <td >{item.assignee_id}</td>
            <td>{(new Date(item.created_at)).toLocaleDateString()}</td></tr>
        ))}
      </tbody>
    </Table>
    </Container>
    <div className="pagination-and-info-notification">
  <nav style={{ marginBottom: '360px', position: 'fixed', bottom: '0', width: '100%' }}>
    {/* Pagination */}
    <ul className="pagination-notification" >
      {/* Previous button */}
      <li className="page-item-notification">
        <span style={{color:'#06A1D2', cursor: currentPage === 1 ? 'not-allowed' : 'pointer'}} className="page-link-notification" onClick={() => handlePageChange(currentPage - 1)}>Previous</span>
      </li>
      {/* Page numbers */}
      {Array.from({ length: Math.ceil(totalRecords / recordsPerPage) }).map((_, index) => (
        <li key={index} className={`page-item-notification ${currentPage === index + 1 ? 'active' : ''}`}>
          <span style={{color: currentPage === index + 1 ? '#06A1D2' : '#ccc'}} className="page-link-notification" onClick={() => handlePageChange(index + 1)}>{index + 1}</span>
        </li>
      ))}
      {/* Next button */}
      <li className="page-item-notification">
        <span style={{color:'#06A1D2', cursor: currentPage === Math.ceil(totalRecords / recordsPerPage) ? 'not-allowed' : 'pointer'}} className="page-link-notification" onClick={() => handlePageChange(currentPage + 1)}>Next</span>
      </li>
    </ul>


  </nav>
   {/* Record information */}
   <div className="record-info-notification" style={{position: 'fixed', bottom: '270px', right: '0px', textAlign: 'right'}}>
    <p className="record-info">
      Showing {Math.min(indexOfFirstRecord + 1, totalRecords)} to {Math.min(indexOfLastRecord, totalRecords)} of {totalRecords} entries
    </p>
  </div>


  
</div>
</div>
</div>

  );
};

export default Notifications;