import React from 'react'

export const Maintenance = () => {
    return (
      <div className='maintenance'>
        <h1>maintenance</h1>
      </div>
    );
  };
  
  export const MaintenanceOne = () => {
    return (
      <div className='maintenance'>
        <h1>maintenance/maintenance1</h1>
      </div>
    );
  };
  
  export const MaintenanceTwo = () => {
    return (
      <div className='maintenance'>
        <h1>maintenance/maintenance2</h1>
      </div>
    );
  };