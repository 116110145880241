import React from 'react' 
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Container, Table } from 'react-bootstrap'; 
import icon from '../components/Asset_Management.png';
import notification from '../components/Notifications.png';
import profileicon from '../components/Ellipse.png';
import profiledown from '../components/ProfileDown.png';
import '../pages/css/Form.css'
import '../pages/css/Assetmanagement.css';
import '../pages/css/Managecolumnpopup.css'
import applyfilter from '../pages/icon/apply-filter.png';
import resetfilter from '../pages/icon/reset-filter.png';
import managecolumns from '../pages/icon/manage-columns.png';
import history from '../pages/icon/history.png';
import addasset from '../pages/icon/addasset.png';
import '../pages/css/Applyfilterpopup.css';
import Slider from 'react-slider';
import close from '../pages/icon/close.png'
import searchicon from '../pages/icon/search.png'
import tablebutton from '../pages/icon/assetmanagement_table_button.png'
import '../pages/css/assettablebuttonpopup.css'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import AddAsset from '../pages/AddAsset.js'
import '../pages/css/AddAssetPopup.css'
import '../pages/css/formtablebuttonpopup.css'

const Forms = () => {
  const MIN = 100;
  const MAX = 12000;
  const [dashboardData, setDashboardData] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false); 
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [values, setValues] = useState([MIN, MAX]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [isManageColumnsPopupOpen, setIsManageColumnsPopupOpen] = useState(false);
  const [isAddAssetPopupOpen, setisAddAssetPopupOpen] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);  
  const [isTableCellPopupOpen, setIsTableCellPopupOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isFocused, setIsFocused] = useState(false);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:5050/dashboard/dashboard-details');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5050/forms/getForms');
        setDrivers(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetch('http://localhost:5050/asset/search')
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setData(data);
        setFilterData(data);
      })
      .catch(err => console.log(err));
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = drivers.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalRecords = drivers.length;

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > Math.ceil(totalRecords / recordsPerPage)) {
      return; // Don't allow going to invalid pages
    }
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    setCurrentPage(1); // Reset pagination when searching
  };

  const checkboxes = [
    { id: 1, label: 'Option 1' },
    { id: 4, label: 'Option 4' },
    { id: 5, label: 'Driver Name' },
    { id: 6, label: 'Asset Name' },
    { id: 8, label: 'Price Range' },
    { id: 40, label: 'action button' }
  ];

  const columnCheckboxes = [
    { id: 1, label: 'Form ID' },
    { id: 2, label: 'Title' },
    { id: 3, label: 'Form Type' },
    { id: 4, label: 'Interval' },
    { id: 5, label: 'Assets Type' },
    { id: 6, label: 'asset subtype entities id' },
    { id: 7, label: 'work_group_id' },
    { id: 8, label: 'work_group_role_ids' },
    { id: 9, label: 'interval_day' },
    { id: 10, label: 'interval_date' },
    { id: 11, label: 'is_parent_form' },
    { id: 12, label: 'parent_form_id' },
    { id: 13, label: 'created_by' },
    { id: 14, label: 'updated_at' },
    { id: 15, label: 'Created Date' },
    { id: 16, label: 'updated_by' },
    { id: 17, label: ' job_end_form' },
    { id: 18, label: ' action  button' },
  ];

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const toggleFilterPopup = () => {
    setIsFilterPopupOpen(!isFilterPopupOpen);
  };

  const toggleManageColumnsPopup = () => {
    setIsManageColumnsPopupOpen(!isManageColumnsPopupOpen);
  };

  const toggleTableCellPopup = (item) => {
    setSelectedRowData(item);
    setIsTableCellPopupOpen(!isTableCellPopupOpen);
  };

  const toggleAddAssetPopup = () => {
    setisAddAssetPopupOpen(!isAddAssetPopupOpen);
  };
  

  const handleCheckboxChange = (checkboxId) => {
    const updatedCheckboxes = selectedCheckboxes.includes(checkboxId)
      ? selectedCheckboxes.filter(id => id !== checkboxId)
      : [...selectedCheckboxes, checkboxId];
    setSelectedCheckboxes(updatedCheckboxes);
    setFiltersApplied(true);
  };

  /*
  const handleOkClick = () => {
    console.log("Selected Checkboxes:", selectedCheckboxes);
    if (selectedCheckboxes.includes(8)) {
      console.log("Price Range:", values);
    }
    toggleFilterPopup();
    setFiltersApplied(true); 
  };*/

  const handleOkClick = () => {
    if (selectedCheckboxes.length > 0 ) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
    toggleFilterPopup();
  };


  const handleInputChange = (index, event) => {
    const newValues = [...values];
    newValues[index] = Number(event.target.value);
    setValues(newValues);
  };

  /*
  const handleTogglePopup = () => {
    setIsOpen(!isFilterPopupOpen);
  };*/

  const handleApplyFilter = () => {
    setIsFilterPopupOpen(true); // Open the filter popup
  };

  const handleResetFilter = () => {
    setSelectedCheckboxes([]);
    setValues([MIN, MAX]);
    setFiltersApplied(false);
    // Check if any filters are applied
  if (!filtersApplied) {
    // If no filters are applied, disable the reset button
    setIsFilterPopupOpen(false);
  }
  };

  /*manage columns*/
  const [columnVisibility, setColumnVisibility] = useState({});

useEffect(() => {
  // Initialize columnVisibility state to make all columns visible initially
  const initialVisibility = {};
  columnCheckboxes.forEach(columnCheckboxes => {
    if ([1, 2, 3, 4, 5, 15, 18 ].includes(columnCheckboxes.id)) {
      initialVisibility[columnCheckboxes.id] = true;
    } else {
      initialVisibility[columnCheckboxes.id] = false;
    }
  });
  setColumnVisibility(initialVisibility);
}, []);

const handleColumnVisibilityChange = (checkboxId) => {
  // This function is for handling checkboxes in the manage columns popup
  setColumnVisibility(prevState => ({
    ...prevState,
    [checkboxId]: !prevState[checkboxId]
  }));
};

const handleRemoveOption = (idToRemove) => {
  const updatedCheckboxes = selectedCheckboxes.filter(id => id !== idToRemove);
  setSelectedCheckboxes(updatedCheckboxes);
  setFiltersApplied(updatedCheckboxes.length > 0);
};

const handleFilter = (value) => {
  if (value === "") {
    setData(filterData);
  } else {
    const res = filterData.filter(f => {
      if (f.name.toLowerCase().includes(value.toLowerCase())) {
      //if (Object.values(f).some(val => typeof val === 'string' && val.toLowerCase().includes(value.toLowerCase()))) {
        return true;
      }

      for (const key in f) {
        if (typeof f[key] === 'string' && f[key].toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    setData(res); // Update data state with filtered data
  }
    setSearch(value);
    setCurrentPage(1);
};

const handleAddAssetClick = () => {
  // Navigate to the AddAsset screen
  setIsOpen(true);
};



  return (
    <>
    <div className='Forms'>
      <nav id='nav' className='nav'>
      <div className="Forms-icon"> <img src={icon}  /> </div>
      <h4 className="Forms-title"> Form </h4>
      <div className="notification-area"><img src={notification} alt="Notification icon" /></div>
      <div className="profile-area">
      <img src={profileicon} alt="Profile Icon" style={{marginLeft:'-550px', height:'40px', width:'40px'}}/>
      <h4 style={{fontSize:'25px', marginTop:'0px', color:'#2B2B2B', marginLeft:'10px', marginRight:'0px', fontfamily: 'inter'}}>James Louis</h4>
      <img src={profiledown} style={{height:'30px', width:'35px'}} alt="Profile Dropdown" /></div>
      </nav>

      {/* Showing number of pages */}
      <div className="record-info-container" style={{position: 'fixed', bottom: '0px', right: '840px', textAlign: 'right',}}>
          <p className="record-info-assetmanagement">
            Showing {Math.min(indexOfFirstRecord + 1, totalRecords)} to {Math.min(indexOfLastRecord, totalRecords)} of {totalRecords} entries
          </p>
        </div>

      <div className="button-container-forms">
        <button className="form-button" onClick={handleApplyFilter}>
          <img src={applyfilter} style={{height:'20px', width:'24px', marginLeft:'0px',  marginRight:'8px', fontfamily: 'inter'}} alt="Apply Filter" />
          Apply Filter
        </button>
        <button className="form-button" onClick={handleResetFilter} disabled={!filtersApplied  || selectedCheckboxes.length === 0} style={{ backgroundColor: (!filtersApplied || selectedCheckboxes.length === 0) ? '#BBBBBB'  : '#5996CE' }}>
          <img src={resetfilter} style={{height:'20px', width:'24px', marginLeft:'25px',  marginRight:'8px', fontfamily: 'inter'}} alt="Reset Filter" />
          Reset Filter
        </button>
        <button className="form-button" onClick={toggleManageColumnsPopup}>
          <img src={managecolumns} style={{height:'20px', width:'24px', marginLeft:'-10px', marginRight:'5px', fontfamily: 'inter'}} alt="Manage Columns" />
          Manage Columns
        </button>
        <button className="form-button">
          <img src={history} style={{height:'20px', width:'24px', marginLeft:'25px',  marginRight:'8px', fontfamily: 'inter',}} alt="History" />
          History
        </button>
       {/**  <Link style={{textDecoration:'none'}} to="/add-asset" className="button-link" onClick={handleAddAssetClick} >
        <img src={addasset} style={{ height: '20px', width: '24px', marginLeft: '30px', marginRight: '8px', fontFamily: 'inter', marginTop:'12px'}} alt="Add Asset" />
        <h5 >Add Asset</h5>
        </Link> */}
        <button style={{textDecoration:'none'}} className="form-button-link" onClick={toggleAddAssetPopup} >
        <img src={addasset} style={{ height: '20px', width: '24px', marginLeft: '-60px', marginRight: '12px', fontFamily: 'inter', marginTop:'0px'}} alt="Add Asset" />
        <h5 >Add Forms</h5>
        </button>
      </div>

      {selectedCheckboxes.length > 0 && (
      <div >
      <h3 style={{color:'black', marginLeft:'295px', fontSize:'20px', marginTop:'15px', fontfamily: 'inter'}}> Filters :</h3> 
      <div style={{color:'black', marginLeft:'370px', marginTop:'-40px', display:'flex', flexWrap: 'wrap', fontfamily: 'inter'}}>
      {selectedCheckboxes.map(id => (
        <div key={id} style={{background:'#EAEFFD', border: '2px solid #4285F4', borderRadius: '10px', padding: '5px', marginRight: '10px' , fontfamily: 'inter'}}>
        <span style={{ color: 'black' }} key={id}>
          {id === 8 ? `Price Range: ${values[0]} - ${values[1]}` : `Option ${id}`}
        </span>
        <button style={{ marginLeft: '5px', marginRight:'5px', border: 'none', cursor: 'pointer', display:'contents'}} onClick={() => handleRemoveOption(id)}>
            <img style={{ marginLeft: '5px' }} src={close}/>
          </button>
        </div>
      ))}
      </div>
    </div>
  )}

    
      
      <div className="search-container-forms">
      <div className="table-header-container-forms">
      <input style={{width:'77%', marginTop:'10px',
      paddingLeft: '40px', 
      backgroundImage: `url(${searchicon})`,
      backgroundPosition: '10px 50%', 
      backgroundSize: '20px 20px', 
      backgroundRepeat: 'no-repeat',
       outline: 'none',
      }}
        type="text"
        value={search}
        onChange={(e) => handleFilter(e.target.value)} // Ensure handleFilter is called on change
        //onChange={(e) => handleSearchChange(e)}
        placeholder="Search for assets here..."
        className="search-input-forms"/>
      </div>
      </div>


      <div className="table-container-forms">
        <Container style={{ marginLeft: '-30px'}}>
          <Table striped bordered hover style={{marginTop:'-90px'}}>
            <thead>
              <tr>
              <th style={{ display: columnVisibility[1] ? 'table-cell' : 'none' }}>{'  Form Id'}</th>
                <th style={{ display: columnVisibility[2] ? 'table-cell' : 'none' }}>{'  Title'}</th>
                <th style={{ display: columnVisibility[3] ? 'table-cell' : 'none' }}>{'  Form type'}</th>
                <th style={{ display: columnVisibility[4] ? 'table-cell' : 'none' }}>{'  Interval'}</th>
                <th style={{ display: columnVisibility[5] ? 'table-cell' : 'none' }}>{'  Assets Type'}</th>
                <th style={{ display: columnVisibility[6] ? 'table-cell' : 'none' }}>{'  asset_subtype_entities_id'}</th>
                <th style={{ display: columnVisibility[7] ? 'table-cell' : 'none' }}>{'  work_group_id'}</th>
                <th style={{ display: columnVisibility[8] ? 'table-cell' : 'none' }}>{'  work_group_role_ids'}</th>
                <th style={{ display: columnVisibility[9] ? 'table-cell' : 'none' }}>{'  interval_day '}</th>
                <th style={{ display: columnVisibility[10] ? 'table-cell' : 'none' }}>{'  interval_date'}</th>
                <th style={{ display: columnVisibility[11] ? 'table-cell' : 'none' }}>{'  is_parent_form'}</th>
                <th style={{ display: columnVisibility[12] ? 'table-cell' : 'none' }}>{'  parent_form_id'}</th>
                <th style={{ display: columnVisibility[13] ? 'table-cell' : 'none' }}>{'  created_by'}</th>
                <th style={{ display: columnVisibility[14] ? 'table-cell' : 'none' }}>{'  updated_at'}</th>
                <th style={{ display: columnVisibility[15] ? 'table-cell' : 'none' }}>{'  Created Date '}</th>
                <th style={{ display: columnVisibility[16] ? 'table-cell' : 'none' }}>{'  updated_by'}</th>
                <th style={{ display: columnVisibility[17] ? 'table-cell' : 'none' }}>{'  job_end_form'}</th>
                <th style={{ display: columnVisibility[18] ? 'table-cell' : 'none' }}>{'        '}</th>
              </tr>
            </thead>
            <tbody>
             {/*{currentRecords.map((item) => ( */} 
              {currentRecords.map((item) => (
                <tr key={item._id}>
                  <td style={{ display: columnVisibility[1] ? 'table-cell' : 'none' }}>{item.display_form_id}</td>
                  <td style={{ display: columnVisibility[2] ? 'table-cell' : 'none' }}>{item.title}</td>
                  <td style={{ display: columnVisibility[3] ? 'table-cell' : 'none' }}>{item.form_type_entities_id}</td>
                  <td style={{ display: columnVisibility[4] ? 'table-cell' : 'none' }}>{item.interval}</td>
                  <td style={{ display: columnVisibility[5] ? 'table-cell' : 'none' }}>{item.assets_entities_id}</td>
                  <td style={{ display: columnVisibility[6] ? 'table-cell' : 'none' }}>{item.asset_subtype_entities_id}</td>
                  <td style={{ display: columnVisibility[7] ? 'table-cell' : 'none' }}>{item.work_group_id}</td>
                  <td style={{ display: columnVisibility[8] ? 'table-cell' : 'none' }}>{item.work_group_role_ids}</td>
                  <td style={{ display: columnVisibility[9] ? 'table-cell' : 'none' }}>{item.interval_day}</td>
                  <td style={{ display: columnVisibility[10] ? 'table-cell' : 'none' }}>{item.interval_date}</td>
                  <td style={{ display: columnVisibility[11] ? 'table-cell' : 'none' }}>{item.is_parent_form}</td>
                  <td style={{ display: columnVisibility[12] ? 'table-cell' : 'none' }}>{item.parent_form_id}</td>
                  <td style={{ display: columnVisibility[13] ? 'table-cell' : 'none' }}>{item.created_by}</td>
                  <td style={{ display: columnVisibility[14] ? 'table-cell' : 'none' }}>{(new Date(item.updated_at)).toLocaleDateString()}</td>
                  <td style={{ display: columnVisibility[15] ? 'table-cell' : 'none' }}>{(new Date(item.created_at)).toLocaleDateString()}</td>
                  <td style={{ display: columnVisibility[16] ? 'table-cell' : 'none' }}>{item.updated_by}</td>
                  <td style={{ display: columnVisibility[17] ? 'table-cell' : 'none' }}>{item.job_end_form}</td>
                  <td style={{ display: columnVisibility[18] ? 'table-cell' : 'none', height:'auto', width:'auto'}}>
                  <button style={{height:'32px', width:'32px', textAlign:'center', marginLeft:'0px', marginBottom:'0px', marginTop:'0px',}} onClick={() => toggleTableCellPopup(item)}><img style={{marginTop:'-30px', marginLeft:'-10px'}} src={tablebutton}/>
                            {isTableCellPopupOpen && ( <div className="popup-inside-table-cell"></div>)}</button></td>
                </tr> 
              ))}
            </tbody>
          </Table>
        </Container>


          {isTableCellPopupOpen && (
          <div className="formtablebuttonpopup">
            <h2>table popup </h2>
            {selectedRowData && (
              <ul>
                {Object.entries(selectedRowData).map(([key, value]) => (
                   <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>
           )}
          <button onClick={toggleTableCellPopup}>OK</button>
          </div>
        )}
      
        {isFilterPopupOpen && (
          <div className="popup">
            <h2>Select Options</h2>
            {checkboxes.map(checkbox => (
              <label key={checkbox.id}>
                <input
                  type="checkbox"
                  checked={selectedCheckboxes.includes(checkbox.id)}
                  onChange={() => handleCheckboxChange(checkbox.id)}
                />
                {checkbox.label}
              </label>
            ))}
            {selectedCheckboxes.
            includes(8) && (
              <div className='box'>
                <h3>Price <span>Range</span></h3>
                <div>
                  <input
                    type="number"
                    value={values[0]}
                    onChange={(e) => handleInputChange(0, e)}
                    min={MIN}
                    max={values[1]} />
                  <input
                    type="number"
                    value={values[1]}
                    onChange={(e) => handleInputChange(1, e)}
                    min={values[0]}
                    max={MAX} />
                </div>
                <small>
                  Current Range: ${values[1] - values[0]}
                </small>
                <Slider className={"slider"}
                  onChange={setValues}
                  value={values}
                  min={MIN}
                  max={MAX} />
              </div>
            )}
            <button onClick={handleOkClick}>OK</button>
          </div>
        )}


      

        {isManageColumnsPopupOpen && (
          <div className="managecolumnspopup">
            <h2>Manage Columns</h2>
            {columnCheckboxes.map(checkbox => (
              <label key={checkbox.id}>
                <input
                  type="checkbox"
                  checked={columnVisibility[checkbox.id]}                  
                  onChange={() => handleColumnVisibilityChange(checkbox.id)}            
                />
                {checkbox.label}
              </label>
            ))}
            <button onClick={toggleManageColumnsPopup}>OK</button>
          </div>
        )}

        {isAddAssetPopupOpen && (
          <div className="addassetpopup">
            <h2>Add Asset </h2>
            <label className="vinInput">Enter vin:</label>
            <input style={{width:'200%', height:'50px', marginTop:'10px', outline:'none'}} type="text" id="vinInput" name="vinInput" placeholder="Enter VIN" />
            <Link  to="/add-asset" className="add-asset" onClick={handleAddAssetClick} ><h5 >Proceed without vin</h5></Link>
            <button style={{marginBottom:'50px', marginTop:'0px', marginBottom:'-50px'}} onClick={toggleAddAssetPopup}>OK</button>
          </div>
        )}        
      </div>

      

      <div className="pagination-and-info">
        <nav style={{ marginBottom: '45px', marginLeft:'-185px', position: 'fixed', bottom: '0', width: '100%' }}>
          <ul className="pagination-assetmanagement">
            <li className="page-item-assetmanagement">
              <span style={{color:'#06A1D2', cursor: currentPage === 1 ? 'not-allowed' : 'pointer'}} className="page-link-assetmanagement" onClick={() => handlePageChange(currentPage - 1)}>Previous</span>
            </li>
            {Array.from({ length: Math.ceil(totalRecords / recordsPerPage) }).map((_, index) => (
              <li key={index} className={`page-item-assetmanagement ${currentPage === index + 1 ? 'active' : ''}`}>
                <span style={{color: currentPage === index + 1 ? '#06A1D2' : '#ccc'}} className="page-link-assetmanagement" onClick={() => handlePageChange(index + 1)}>{index + 1}</span>
              </li>
            ))}
            <li className="page-item-assetmanagement">
              <span style={{color:'#06A1D2', cursor: currentPage === Math.ceil(totalRecords / recordsPerPage) ? 'not-allowed' : 'pointer'}} className="page-link-assetmanagement" onClick={() => handlePageChange(currentPage + 1)}>Next</span>
            </li>
          </ul>
        </nav>
      </div>


      
    </div>
    </>
  );
};

export default Forms


