import React, { useState } from 'react';
import './css/LoginForm.css';
//import { useHistory } from 'react-router-dom'; // Import useHistory for redirection
import logo from '../components/Login_logo.png';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  //const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();

    console.log("Email:", email);
    console.log("Password:", password);

    // API login request
    const response = await fetch('http://localhost:5050/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.ok) {
      // Redirect to dashboard upon successful login

      window.location.href = '/dashboard';
    } else {
      alert('Please enter email address and password, Please try again.');
    }
  };

  return (
    <div className="login-page">
    <div className="image-container">
      <img src={logo} alt="Left section image" />
    </div>
    <div className="form-container">
      <h2>Welcome back!</h2>
      <h4>Please enter your credentials below.</h4>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email Address</label><br></br>
          <input
            type="email"
            id="email"
            value={email}
            placeholder="     Enter Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label><br></br>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="    Enter Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p className="error-message">{error}</p>}
        </div>
        <button type="submit">LOGIN</button>
        <div className="forgot-password">
          <a src="/">Forgot your Password?</a>
        </div>
      </form>
    </div>
  </div>
);
}

export default LoginForm;
