import './App.css';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Predefinedentities, ReportsOne, ReportsTwo } from './pages/predefinedentities';
import Forms from './pages/Forms';
import { History, HistoryOne } from './pages/History';
import { Maintenance, MaintenanceOne, MaintenanceTwo } from './pages/Maintenance';
import Assetmanagement from './pages/Asset_Management';
import Notifications from './pages/Notifications';
import Driver from './pages/Driver';
import Accessmanagement from './pages/Access_Management';
import LoginForm from './pages/LoginForm';
import Dashboard from './pages/Dashboard';
import React, { useState } from 'react';
import AddAsset from './pages/AddAsset.js'


function App() {
  
  return (
    <Router>
        <Switch>
        <Route path='/' exact>
          <LoginForm />
        </Route>
        <Route>

      <Sidebar />
      <Switch>
        {/*<Route path='/LoginForm' exact component={LoginForm} />*/}
        <Route path='/dashboard' exact component={Dashboard} />
        <Route path='/forms' exact component={Forms} />
        <Route path='/history' exact component={History} />
        <Route path='/history/history1' exact component={HistoryOne} />
        <Route path='/maintenance' exact component={Maintenance} />
        <Route path='/maintenance/maintenance1' exact component={MaintenanceOne} />
        <Route path='/maintenance/maintenance2' exact component={MaintenanceTwo} />
        <Route path='/assetmanagement' exact component={Assetmanagement} />
        <Route path="/add-asset" component={AddAsset} />
        <Route path='/predefinedentities' exact component={Predefinedentities} />
        <Route path='/reports/reports1' exact component={ReportsOne} />
        <Route path='/reports/reports2' exact component={ReportsTwo} />
        <Route path='/notifications' exact component={Notifications} />
        <Route path='/driver' exact component={Driver} />        
        <Route path='/accessmanagement' exact component={Accessmanagement}/>
    
      </Switch>
      </Route>
      </Switch>

      {/*
      <nav id='nav'>
      <img src={icon} alt="Home Icon" style={{ width: '30px', height: '30px', alignItems: 'center', marginRight: '6px', top: '10px'}}/> Dashboard 
  </nav>  */}
    </Router>
  );
}

export default App;
