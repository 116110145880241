import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import icon from '../components/home.png';
import notification from '../components/Notifications.png';
import profileicon from '../components/Ellipse.png';
import profiledown from '../components/ProfileDown.png';
import '../pages/css/Driver.css';
import axios from 'axios';
import addDriver from '../pages/addDriver.js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


const Driver = () => {
  const [drivers, setDrivers] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5050/driver/getAllDrivers');
        setDrivers(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleDriverActiveStatus = async (_id, newStatus) => {
    try {
      await axios.put(`http://localhost:5050/driver/${_id}/toggle`, {
        isActive: newStatus
      });

      // Update the isActive state locally
      setDrivers(prevDrivers =>
        prevDrivers.map(driver =>
          driver._id === _id ? { ...driver, is_active: newStatus } : driver
        )
      );
    } catch (error) {
      console.error('Failed to toggle active status', error);
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = drivers.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalRecords = drivers.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);



  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return; // Don't allow going to invalid pages
    }
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    const currentRange = Math.floor((currentPage - 1) / maxPagesToShow) * maxPagesToShow;
    return Array.from({ length: Math.min(maxPagesToShow, totalPages - currentRange) }, (_, i) => currentRange + i + 1);
  };




    return (
      
    <div className="driver">
    <nav id='nav' className='nav'>
      <div className="asset-icon"> <img src={notification}  /> </div>
      <h4 className="driver-title">Driver</h4>
      <div className="driver-area"><img src={notification} alt="Notification icon" /></div>
      
      <div className="profile-area">
      <img src={profileicon} alt="Profile Icon" style={{marginLeft:'-550px'}}/>
      <h4 style={{fontSize:'25px', marginTop:'10px', color:'#2B2B2B', marginLeft:'10px', marginRight:'0px'}}>James Louis</h4>
      <img src={profiledown} style={{height:'30px', width:'35px'}} alt="Profile Dropdown" /></div>
      </nav>

 


      <div className="table-container">
    
            <table className="header-table">
                <thead>
                    <tr>
                        <th>Driver Name</th>
                        <th>whatsapp_number</th>
                        <th>status</th>
                        <th>email</th>
                    </tr>
                </thead>
                </table>

          {/* Table body */}
          {currentRecords.map((item) => (
  <div key={item._id} className="card">
    <table className="data-table">
      <tbody>
        <tr style={{ borderCollapse: 'separate', borderSpacing: '0 20px' }}>
          <td>{item.UserName}</td>
          <td>{item.whatsapp_number}</td>
          <td>
            <div className="toggle-container">
              <span>{item.is_active ? 'Active' : 'Inactive'}</span>
            </div>
          </td>
          <td>{item.email}</td>
        </tr>
      </tbody>
    </table>
  </div>
))}

      
<div className="pagination-and-info">
    <nav style={{ marginLeft: '-760px', marginBottom: '230px', position: 'fixed', bottom: '0', width: '100%', fontfamily: 'inter' }}>
      <ul className="pagination-driver">
        <li className="page-item-driver">
          <span style={{ color: '#06A1D2', cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }} className="page-link-notification" onClick={() => handlePageChange(currentPage - 1)}>Previous</span>
        </li>
      {getPageNumbers().map(pageNumber => (
        <li key={pageNumber} className={`page-item-driver ${currentPage === pageNumber ? 'active' : ''}`}>
            <span style={{ color: currentPage === pageNumber ? '#06A1D2' : '#ccc' }} className="page-link-driver" onClick={() => handlePageChange(pageNumber)}>{pageNumber}</span>
        </li>
      ))}
      {totalPages > 3 && <li className="page-item-driver disabled"><span className="page-link-driver">...</span></li>}
        <li className="page-item-notification">
             <span style={{ color: '#06A1D2', cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }} className="page-link-driver" onClick={() => handlePageChange(currentPage + 1)}>Next</span>
        </li>
      </ul>
    </nav>
{/* Record information */}
<div className="record-info-container" style={{position: 'fixed', bottom: '120px', right: '740px', textAlign: 'right', fontfamily: 'inter'}}>
  <p className="record-info">
    Showing {Math.min(indexOfFirstRecord + 1, totalRecords)} to {Math.min(indexOfLastRecord, totalRecords)} of {totalRecords} entries
  </p>
</div>


</div>
</div>
</div>
  );
};

export default Driver;
