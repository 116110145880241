import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 18px;
  margin: 20px 10px;
  color: black;
  font-family: 'Inter', sans-serif;
  white-space: nowrap;
  font-weight: 500;
  
  &:hover {
    background: #287E73;
    border-left: 4px solid #287E73;
    cursor: pointer;
    border-radius: 10px;
    margin: 20px 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 40px;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    white-space: nowrap;
    font-weight: 500;
    margin-left: 10px;
  }

  &:hover .sidebar-icon {
    display: none;
  }

  &:hover .hover-icon {
    display: block;
  }

  .hover-icon {
    display: none;
    margin-left: -5px; 
  }
`;

const SidebarLabel = styled.span`
  margin-left: 12px;
`;

const DropdownLink = styled(Link)`
  background: white;
  height: 40px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 18px;
  border-radius: 10px;
  margin: 10px 10px;
  font-weight: 500;s

  &:hover {
    background: #287E73;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 40px;
    padding-left: 3rem;
  }

  &:hover .sidebar-icon {
    display: none;
  }

  &:hover .hover-icon {
    display: block;
  }

  .hover-icon {
    display: none;
    margin-left: -20px; /* Add margin-left for hover icon */
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {item.icon}
          {item.hoverIcon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((subItem, index) => {
          return (
            <DropdownLink to={subItem.path} key={index}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {subItem.icon}
                {subItem.hoverIcon}
                <SidebarLabel>{subItem.title}</SidebarLabel>
              </div>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
