import React from 'react'

const Access_Management = () => {
  return (
    <div className='accessmanagement'>
      this is access management
    </div>
  )
}

export default Access_Management
