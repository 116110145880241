import React, { useState, useEffect, createContext, useContext } from 'react';
import styled from 'styled-components';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import navlogo from './nav_logo.png';
import './Sidebar.css';

// Create a context to manage the sidebar state
const SidebarContext = createContext();

const NavIcon = styled.div`
  height: 90px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${navlogo});
  background-color: #037E69;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: calc(100% - 60px) center;
  margin-left: 20px;
  border-radius: 8px;
  margin-top: ${({ isCollapsed }) => (isCollapsed ? '16px' : '16px')};
  margin-bottom: ${({ isCollapsed }) => (isCollapsed ? '60px' : '45px')};
  margin-right: 10px;
  padding-right: auto;
  cursor: pointer;
`;

const SidebarNav = styled.nav`
  margin-top: ${({ isCollapsed, isSmallScreen }) => (isCollapsed && isSmallScreen ? '16px' : '-60px')};
  background: white;
  width: ${({ isSmallScreen, isCollapsed }) => (isCollapsed ? '68px' : isSmallScreen ? '150px' : '265px')};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 10;
  border-right: 1px solid #DCDCDC;
  overflow-y: auto;
  transition: width 0.3s ease, margin-top 0.3s ease;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = ({ children }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    document.body.classList.toggle('collapsed', isCollapsed);
  }, [isCollapsed]);
  

  return (
    <SidebarContext.Provider value={{ isCollapsed }}>
      <IconContext.Provider value={{ color: '#287E73' }}>
        <SidebarNav isSmallScreen={isSmallScreen} isCollapsed={isCollapsed}>
          <SidebarWrap>
            <NavIcon onClick={handleToggle} isCollapsed={isCollapsed} />
            {SidebarData.map((item, index) => (
              <SubMenu item={item} key={index} className="sidebar-item"/>
            ))}
          </SidebarWrap>
        </SidebarNav>
        <MainContent>{children}</MainContent>
      </IconContext.Provider>
    </SidebarContext.Provider>
  );
};

export default Sidebar;

const MainContent = styled.div`
  margin-left: ${({ isCollapsed }) => (isCollapsed ? '68px' : '265px')};
  transition: margin-left 0.3s ease;
  padding: 20px;
`;

export const Content = ({ children }) => {
  const { isCollapsed } = useContext(SidebarContext);
  return <MainContent isCollapsed={isCollapsed}>{children}</MainContent>;
};
