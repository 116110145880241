import React from 'react';
import '../pages/css/AddAsset.css'
import icon from '../components/Asset_Management.png';
import notification from '../components/Notifications.png';
import profileicon from '../components/Ellipse.png';
import profiledown from '../components/ProfileDown.png';

const AddAsset = () => {
  return (
    <div>

     {/* navigation  */}
      <nav id='nav' className='nav'>
      <div className="Addasset-icon"> <img src={icon}  /> </div>
      <h4 className="Addasset-title">Add Asset </h4>
      <div className="notification-area"><img src={notification} alt="Notification icon" /></div>
      
      <div className="profile-area">
      <img src={profileicon} alt="Profile Icon" style={{marginLeft:'-550px', height:'40px', width:'40px'}}/>
      <h4 style={{fontSize:'25px', marginTop:'0px', color:'#2B2B2B', marginLeft:'10px', marginRight:'0px', fontfamily: 'inter'}}>James Louis</h4>
      <img src={profiledown} style={{height:'30px', width:'30px'}} alt="Profile Dropdown" /></div>
      </nav>

      <div className="profile-container">
        <div className="image-placeholder">
        <img src="https://via.placeholder.com/150" alt="Placeholder" />
      </div>
      <div className="profile-form-container">
      <div className="inputs-container">
      <label htmlFor="input1">Asset Name</label>
        <input style={{width:'400px'}} type="text" id="input1" placeholder="Enter Name" />
        <label  style={{width:'400px'}} htmlFor="input2" class="active-asset-label"> Active Asset 
                <div class="toggle-container">
                   <input type="checkbox" id="input2" />
                   <div class="toggle"></div>
                </div>
      </label>      
      </div>
      </div>
      </div>


    {/*
      <div className="profile-container">
        <div className="image-placeholder">
        <img src="https://via.placeholder.com/150" alt="Placeholder" />
      </div>
      <div className="profile-form-container">
      <div className="inputs-container">
      <label htmlFor="input1">Asset Name</label>
        <input style={{width:'400px'}} type="text" id="input1" placeholder="Enter Name" />
        <label  style={{width:'400px'}} htmlFor="input2" class="active-asset-label"> Active Asset 
                <div class="toggle-container">
                   <input type="checkbox" id="input2" />
                   <div class="toggle"></div>
                </div>
      </label>      
      </div>
      </div>
      </div>
  */}

    {/*
      <div className="profile-container">
        <div className="image-placeholder">
        <img src="https://via.placeholder.com/150" alt="Placeholder" />
      </div>
      <div className="profile-form-container">
      <div className="inputs-container">
      <label htmlFor="input1">Asset Name</label>
        <input style={{width:'400px'}} type="text" id="input1" placeholder="Enter Name" />
        <label  style={{width:'400px'}} htmlFor="input2" class="active-asset-label"> Active Asset 
                <div class="toggle-container">
                   <input type="checkbox" id="input2" />
                   <div class="toggle"></div>
                </div>
      </label>      
      </div>
      </div>
      </div>
  */}

    </div>
  );
};

export default AddAsset;