import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import home from './home.png';
import homewhite from './Homewhite.png'
import { ReactComponent as HomeIcon } from './home.svg';
import { ReactComponent as FormIcon } from './form.svg';
import form from './form.png';
import history from './history.png';
import maintenance from './Maintenance.png';
import Maintenancewhite from './Maintenancewhite.png'
import assetmanagement from './Asset_Management.png';
import Asset_Managementwhite from './Asset_Managementwhite.png'
import predefinedentities from './Predefined Entities.png';
import notifications from './Notifications.png';
import Notificationswhite from './Notificationswhite.png'
import Driver from './Driver.png';
import accessmanagement from './Access_Management.png';
import downarrow from './ProfileDown.png'
import uparrow from './uparrow.png'
import { faDisplay } from '@fortawesome/free-solid-svg-icons';


export const SidebarData = [

  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <img src={home} className="sidebar-icon" alt="home icon" width="24" height="24" />,
    hoverIcon: <img src={homewhite} className="hover-icon" alt="home icon" width="24" height="24" style={{marginRight:'10px'}}/>,
    //icon: <HomeIcon width="24" height="24"/>,
  }, 

  {
    title: 'Forms', 
    path: '/forms',
    //icon: <img src={form} width="20" height="20" />,
    icon: <FormIcon  width="24" height="24"/>,
   // hoverIcon: <img src={Maintenancewhite} className="hover-icon" alt="home icon" width="24" height="24"/>,
  },

  {
    title: 'History',
    path: '/history',
    icon: <img src={history} width="24" height="24" />, //style={{ ''}}
    //icon: <IoIcons.IoIosPaper />,
    iconClosed: <img src={downarrow} width="24" height="24" />, 
    iconOpened: <img src={uparrow} width="24" height="24" />,

    subNav: [
      {
        title: 'History',
        path: '/history/history1',
        icon: <img src={history} width="24" height="24" />,        
        cName: 'sub-nav'
      },
    ]
  },

  {
    title: 'Maintenance',
    path: '/maintenance',
    icon: <img src={maintenance} width="24" height="24" />,
    hoverIcon: <img src={Maintenancewhite} className="hover-icon" alt="home icon" width="24" height="24"/>,
    iconClosed: <img src={downarrow} width="24" height="24" />, //RiIcons.RiArrowDownSFill
    iconOpened: <img src={uparrow} width="24" height="24" />, //RiIcons.RiArrowUpSFill
    

    subNav: [
      {
        title: 'Maintenance',
        path: '/maintenance/maintenance1',
        icon: <img src={maintenance} width="24" height="24" />,
        hoverIcon: <img src={Maintenancewhite} className="hover-icon" alt="home icon" width="24" height="24"/>,
        cName: 'sub-nav'
      },
      {
        title: 'Maintenance 2',
        path: '/maintenance/maintenance2',
        icon: <img src={maintenance} width="24" height="24" />,
        hoverIcon: <img src={Maintenancewhite} className="hover-icon" alt="home icon" width="24" height="24"/>,
        cName: 'sub-nav'
      },
    ]
  },

  {
    title: 'Asset Management',
    path: '/assetmanagement',
    icon: <img src={assetmanagement} width="24" height="24" />,
    hoverIcon: <img src={Asset_Managementwhite} className="hover-icon" alt="home icon" width="24" height="24"  />,
  },

  {
    title: 'Predefined Entities',
    path: '/predefinedentities',
    icon: <img src={predefinedentities} width="24" height="24" />,
    iconClosed: <img src={downarrow} width="24" height="24" />, 
    iconOpened: <img src={uparrow} width="24" height="24" />,

    subNav: [
      {
        title: 'Reports',
        path: '/reports/reports1',
        icon: <img src={predefinedentities} width="24" height="24" />,
        cName: 'sub-nav'
      },
      {
        title: 'Reports 2',
        path: '/reports/reports2',
        icon: <img src={predefinedentities} width="24" height="24" />,
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Notifications',
    path: '/notifications',
    icon: <img src={notifications} width="24" height="24" />,
    hoverIcon: <img src={Notificationswhite} className="hover-icon" alt="home icon" width="24" height="24"/>,
    
  },
  {
    title: 'Driver',
    path: '/driver',
    icon: <img src={Driver} width="24" height="24" />,
  },
  {
    title: 'Access Management',
    path: '/accessmanagement',
    icon: <img src={accessmanagement} width="24" height="24" />
  }
];
